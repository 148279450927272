import axios from 'axios';
import { toast } from 'react-toastify';

const authAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the access token
authAxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle errors
authAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user_profile');
            localStorage.removeItem('order_id');
            toast.error('session expired. please log in again.');
            window.location.href = '/';
        }
        else{
            // const message = error.response?.data?.message || 'An error occurred';
            // toast.error(message);
        }
        return Promise.reject(error);
    }
);

export default authAxiosInstance;