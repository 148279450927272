import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiLogOut, FiHome, FiUserPlus } from 'react-icons/fi';
import { GiBrain } from 'react-icons/gi'; // Import the mind palace icon
import { toast } from 'react-toastify';
import { getUserStatus } from '../utils/apis/getUserStatus';

const Header = () => {
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchUserStatus = async () => {
            const result = await getUserStatus();
            if (result.success) {
                setStatus(result.data);
                handleRedirection(result.data);
            } else {
                toast.error('failed to check user status');
            }
        };

        fetchUserStatus();

        const interval = setInterval(fetchUserStatus, 1000); // Fetch every second

        return () => clearInterval(interval); // Cleanup on unmount

    }, [location]);

    const handleRedirection = (data) => {
        if (
            (data.has_active_plan === false && data.is_exiting_customer === false) ||
            (data.is_on_trial_period_plan === true && data.trial_days_left <= 0) ||
            (data.is_on_trial_period_plan === true && data.posts_left <= 0)
        ) {
            if (location.pathname !== '/plans' && location.pathname !== '/plans?expire=1') {
                navigate('/plans?expire=1');
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_profile');
        localStorage.removeItem('order_id');
        navigate('/');
    };

    function numberToWords(num) {
        const words = [
            'zero', 'one', 'two', 'three', 'four', 'five',
            'six', 'seven', 'eight', 'nine', 'ten',
            'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen'
        ];

        if (num >= 0 && num <= 15) {
            return words[num];
        } else {
            return num;  // Fallback to returning the number itself if out of range
        }
    }

    return (
        <header className="bg-black text-white flex flex-col md:flex-row justify-between items-center p-4 space-y-2 md:space-y-0">
            <div className="flex justify-between items-center w-full md:w-auto">
                <Link to="/home" className="text-2xl md:text-3xl font-bold">//postgen</Link>
                <div className="flex items-center space-x-4 md:hidden">
                    <GiBrain className="text-xl cursor-pointer" onClick={() => navigate('/mind-palace')} />
                    <FiUserPlus className="text-xl cursor-pointer" onClick={() => navigate('/creators')} />
                    <FiLogOut className="text-xl cursor-pointer" onClick={handleLogout} />
                </div>
            </div>
            {status && status.is_on_trial_period_plan && status.trial_days_left > 0 && (
                <div className="text-sm cursor-pointer font-bold" onClick={() => navigate('/plans')}>
                    {numberToWords(status.trial_days_left)} days or {status.posts_left} posts remaining. <span className="underline font-bold">upgrade now</span>
                </div>
            )}
            <div className="hidden md:flex items-center space-x-4">
                <span className="relative text-sm cursor-pointer pt-1 font-bold" onClick={() => navigate('/mind-palace')}>
                    //mind palace
                    <span className="absolute top-0 right-0 -mt-2.5 -mr-3 h-2.5 w-2.5 bg-red-500 rounded-full animate-flash"></span>
                </span>
                <span className="text-sm cursor-pointer pt-1 font-bold" onClick={() => navigate('/creators')}>//my creators</span>
                <FiLogOut className="text-xl cursor-pointer font-bold" onClick={handleLogout} />
            </div>
        </header>
    );
};

export default Header;