import React, { useEffect, useState } from 'react';
import CustomDropdown from '../components/CustomDropdownWithLabel';
import CustomTextInput from '../components/CustomTextInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { sendLoginRequest } from '../utils/apis/userLogin';
import countryOptions from "../components/countryList";
import { clarity } from "react-microsoft-clarity";
import * as amplitude from "@amplitude/analytics-browser";
import signInLargeActive from '../assets/img/Sign-in-Large---Active.png';
import signInLargeDefault from '../assets/img/Sign-in-Large---Default.png';
import signInLargeHover from '../assets/img/Sign-in-Large---Hover.png';

const EnterPhoneNumber = () => {
    const [linkedinButtonImage, setLinkedinButtonImage] = useState(signInLargeDefault);
    const [countryCode, setCountryCode] = useState('🇮🇳 +91');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (accessToken && refreshToken) {
            amplitude.track('Returning User New Session');
            navigate('/home');
        }
    }, [navigate]);

    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleLinkedInLogin = () => {
        const clientId = '77ayrz8qhc3qoy';
        const redirectUri = encodeURIComponent(process.env.REACT_APP_LINKEDIN_REDIRECT_URL);
        const state = encodeURIComponent('random_state_string');
        const scope = encodeURIComponent('openid profile w_member_social email');

        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

        window.location.href = linkedInAuthUrl;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const phoneNumberWithoutLeadingZero = phoneNumber.replace(/^0+/, '');
        setLoading(true);
        const phoneNumberWithoutPlus = `${countryCode}${phoneNumberWithoutLeadingZero}`
            .replace(/^\D+/g, '') // Remove all leading non-digit characters (including emojis and +)
            .replace(/\D/g, '');  // Remove any remaining non-digit characters (including spaces and special chars)

        if (phoneNumberWithoutPlus.length <= 6) {
            toast.error('please check the mobile number entered');
        } else {
            const result = await sendLoginRequest(phoneNumberWithoutPlus);

            if (result.success) {
                const { user_id, order_id } = result.data;
                localStorage.setItem('user_id', user_id);
                clarity.identify('USER_ID', { userProperty: user_id });
                localStorage.setItem('order_id', order_id);
                toast.success('otp sent successfully!');
                navigate('/verify');
                amplitude.setUserId(user_id);
                amplitude.track('Mobile Number Entered', {
                    phoneNumber: phoneNumberWithoutPlus,
                });
            } else {
                toast.error('failed to send otp');
            }
        }

        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-4">
            <div className="w-full max-w-md card spacious">
                <h2 className="text-3xl mb-6 text-black" style={{textTransform: 'lowercase'}}>get started with
                    postgen</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <div className="flex flex-col md:flex-row">
                            <div className="mb-4 md:mb-0 md:mr-2 w-full md:w-48">
                                <CustomDropdown
                                    name="Influencer"
                                    placeholder="country code"
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    options={countryOptions.map((option) => ({
                                        value: option.label,
                                        label: option.label,
                                    }))}
                                />
                            </div>
                            <CustomTextInput
                                type="tel"
                                name="phone_number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="mobile number"
                                className="text-transform: lowercase"
                            />
                        </div>
                        <p className="text-sm text-gray-600 mt-2" style={{textTransform: 'lowercase'}}>
                            don't have a mobile number? <br/>
                            <button
                                type="button"
                                onMouseEnter={() => setLinkedinButtonImage(signInLargeHover)}
                                onMouseLeave={() => setLinkedinButtonImage(signInLargeDefault)}
                                onMouseDown={() => setLinkedinButtonImage(signInLargeActive)}
                                onMouseUp={() => setLinkedinButtonImage(signInLargeHover)}
                                onClick={handleLinkedInLogin}
                                style={{
                                    backgroundImage: `url(${linkedinButtonImage})`,
                                    backgroundSize: 'cover',
                                    width: '180px', // Adjust width based on your image size
                                    height: '34px',  // Adjust height based on your image size
                                    border: 'none',
                                    cursor: 'pointer',
                                    outline: 'none',
                                }}
                            />

                        </p>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="btn font-bold"
                                disabled={loading}
                                style={{textTransform: 'lowercase'}}
                            >
                                {loading ? <Loader/> : 'next'}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default EnterPhoneNumber;