import axiosOpenInstance from "../axiosOpenInstance";


export const sendLoginRequest = async (phoneNumberWithoutPlus) => {
    try {
        const response = await axiosOpenInstance.post('/user/login/', {
            phone_number: phoneNumberWithoutPlus,
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};