import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Articles from './Articles';
import Topics from './Topics';
import Loader from '../../components/Loader';
import { getCategoryArticles } from '../../utils/apis/mindpalace/getCategoryArticles';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';
import CustomTextInput from '../../components/CustomTextInput';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import MindPalaceGenerator from '../MindPalaceGenerator';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'black',
        height: '3px',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'lowercase',
    fontWeight: '600',
    marginRight: theme.spacing(1),
    color: 'black',
    '&.Mui-selected': {
        color: 'black',
    },
    '&:hover': {
        boxShadow: '0px -2px 0px 0px black inset',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'black',
        color: 'white',
    },
}));

const MindPalaceList = () => {
    const { categoryId, categoryName } = useParams();
    const [articleForPostGen, setArticleForPostGen] = useState(null);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchArticles = async (search = '') => {
        setLoading(true);
        const result = await getCategoryArticles(categoryId, search);
        if (result) {
            setArticles(result);
        } else {
            toast.error('Failed to load articles');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchArticles();
    }, [categoryId]);

    const debouncedFetchArticles = useCallback(
        debounce((search) => fetchArticles(search), 300),
        []
    );

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        debouncedFetchArticles(event.target.value);
    };

    const generatePostModal = (article) => {
        console.log("*****",article)
        setArticleForPostGen(article);
        setIsPaneOpen(true);
    };

    return (
        <div className="min-h-screen flex flex-col">
            <SlidingPane
                closeIcon={<div>click here to go back</div>}
                isOpen={isPaneOpen}
                from="left"
                width="95%"
                hideHeader={true}
                onRequestClose={() => {
                    setIsPaneOpen(false);
                }}
            >
                <MindPalaceGenerator passedTopic={articleForPostGen} />
            </SlidingPane>
            <Header />
            <div className="flex-grow flex flex-col items-center justify-top p-4">
                <div className="w-full max-w bg-white rounded-xl border-black border-2">
                    <Box>
                        <StyledTabs value={tabIndex} onChange={handleChange}>
                            <StyledTab label="our content" />
                            <StyledTab label="your topics" />
                        </StyledTabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0}>
                        <div className="w-full md:w-1/3">
                            <div className="block md:hidden mb-2">
                                <h3 className="text-md font-bold">curated {categoryName.toLowerCase()} articles</h3>
                            </div>
                            <CustomTextInput
                                type="text"
                                placeholder={`${
                                    window.innerWidth < 768 ? 'search' : `search curated ${categoryName.toLowerCase()} articles`
                                }`}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className={`input bg-gray-100 w-full`}
                            />
                        </div>
                        {loading ? (
                            <Loader/>
                        ) : (
                            <Articles articles={articles} generatePostModal={generatePostModal}/>
                        )}
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Topics categoryId={categoryId} generatePostModal={generatePostModal}/>
                    </TabPanel>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default MindPalaceList;