// DeleteConfirmationModal.jsx
import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, content }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
            <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full" onClick={(e) => e.stopPropagation()}>
                <div className="text-center">
                    <h3 className="text-xl mb-4 ">are you sure?</h3>
                    <p>do you want to delete the {content} ?</p>
                    <div className="flex justify-center mt-4">
                        <button className="btn mr-4" onClick={onConfirm}>yes, delete</button>
                        <button className="btn" onClick={onClose}>no, cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;