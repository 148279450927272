import React, { useState, useEffect, useRef } from 'react';
import BorderlessInput from "./BorderlessInput";

const CustomDropdown = ({ value, onChange, options, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionValue) => {
        onChange({ target: { value: optionValue } });
        setIsOpen(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const sanitizeString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');

    // const filteredOptions = options.filter(option => {
    //     console.log("**********")
    //     console.log("Search -> ", searchTerm.toLowerCase())
    //     console.log("In -> ",  option.label)
    //     console.log("Result -> ",option.label.includes(searchTerm.toLowerCase()))
    //     console.log("**********")
    //     // Remove emojis by extracting digits and text after the initial emoji
    //     //const optionLabel = option.label.replace(/^[^\d]+/, '').trim(); // Match anything that is not a digit or plus sign
    //     optionLabel.toLowerCase().includes(searchTerm.toLowerCase());
    // });

    const filteredOptions = options.filter(option =>
        //option.label.toLowerCase().includes(searchTerm.toLowerCase())
        sanitizeString(option.label).includes(sanitizeString(searchTerm))
    );

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div className="relative">
                <button
                    type="button"
                    onClick={handleToggle}
                    className="input w-full px-3 py-2 border rounded focus:outline-none focus:ring-1 focus:ring-black flex justify-between items-center text-black"
                >
                    {value || 'Select'}
                    <svg className="fill-current h-6 w-6 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                    </svg>
                </button>
                <label className="absolute left-3 -top-2.5 bg-white px-1 text-sm transition-all text-black font-bold">
                    {placeholder}
                </label>
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-48 overflow-auto text-black">
                    <BorderlessInput
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search Here / Scroll"
                    />
                    <ul>
                        {filteredOptions.map((option) => (
                            <li
                                key={option.value}
                                className="cursor-pointer px-4 py-2 hover:bg-gray-200 text-sm border-black text-black"
                                onClick={() => handleOptionClick(option.label)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;