import React from 'react';

const BorderlessInput = ({ type, value, onChange, placeholder }) => {
    const handleFocus = (event) => {
        if (event.target.value === placeholder) {
            event.target.value = '';
        }
    };

    const handleBlur = (event) => {
        if (event.target.value === '') {
            event.target.value = placeholder;
        }
    };

    return (
        <div className="relative w-full">
            <input
                type={type}
                value={value === placeholder ? '' : value}
                onChange={onChange}
                className="w-full px-3 py-2 rounded focus:outline-none focus:ring-1 focus:ring-white text-sm text-black font-bold"
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
};

export default BorderlessInput;