import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import MindPalaceGenerator from './MindPalaceGenerator';
import { getCategories } from "../utils/apis/mindpalace/getCategories";
import { getCategoryArticles } from "../utils/apis/mindpalace/getCategoryArticles";
import { getTopics } from "../utils/apis/mindpalace/getTopics";
import { getTopicArticles } from "../utils/apis/mindpalace/getTopicArticles";
import { createTopic } from "../utils/apis/mindpalace/createTopic";
import { refreshTopicArticles } from "../utils/apis/mindpalace/refreshTopicArticle";
import CategoryContent from "../components/mindpalace/CategoryContent";
import TopicTabs from "../components/mindpalace/TopicTabs";

Modal.setAppElement('#root');

const ContentManager = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryArticles, setCategoryArticles] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [topicArticles, setTopicArticles] = useState([]);
    const [newTopicName, setNewTopicName] = useState('');
    const [loading, setLoading] = useState(false);
    const [creatingTopic, setCreatingTopic] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTopic, setModalTopic] = useState('');

    useEffect(() => {
        fetchCategories();
        fetchAllTopics();
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            setSelectedCategory(categories[0]);
            fetchCategoryArticles(categories[0].id);
        }
    }, [categories]);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const result = await getCategories();
            if (result) {
                setCategories(result);
            } else {
                toast.error('Failed to load categories');
            }
        } catch (error) {
            toast.error('An error occurred while fetching categories');
        } finally {
            setLoading(false);
        }
    };

    const fetchCategoryArticles = async (categoryId) => {
        setLoading(true);
        try {
            const result = await getCategoryArticles(categoryId);
            if (result) {
                setCategoryArticles(result);
            } else {
                toast.error('Failed to load category articles');
            }
        } catch (error) {
            toast.error('An error occurred while fetching articles');
        } finally {
            setLoading(false);
        }
    };

    const fetchAllTopics = async () => {
        setLoading(true);
        try {
            const result = await getTopics();
            if (result) {
                setTopics(result);
            } else {
                toast.error('Failed to load topics');
            }
        } catch (error) {
            toast.error('An error occurred while fetching topics');
        } finally {
            setLoading(false);
        }
    };

    const fetchTopicArticles = async (topicId) => {
        setLoading(true);
        try {
            const result = await getTopicArticles(topicId);
            if (result) {
                setTopicArticles(result);
            } else {
                toast.error('Failed to load topic articles');
            }
        } catch (error) {
            toast.error('An error occurred while fetching topic articles');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateTopic = async () => {
        if (!newTopicName.trim()) {
            toast.error('Please enter a topic name');
            return;
        }
        if (!selectedCategory) {
            toast.error('Please select a category');
            return;
        }
        const categoryId = selectedCategory.id;
        try {
            setCreatingTopic(true);
            const result = await createTopic(newTopicName, categoryId);
            if (result) {
                toast.success('Topic created successfully!');
                setNewTopicName('');
                fetchAllTopics();
            } else {
                toast.error('Failed to create topic');
            }
        } catch (error) {
            toast.error('An error occurred while creating the topic');
        } finally {
            setCreatingTopic(false);
        }
    };

    const handleRefreshTopic = async (topicId) => {
        try {
            const result = await refreshTopicArticles(topicId);
            if (result) {
                toast.success('Topic articles refreshed successfully!');
                fetchTopicArticles(topicId);
            } else {
                toast.error('Failed to refresh topic articles');
            }
        } catch (error) {
            toast.error('An error occurred while refreshing topic articles');
        }
    };

    const handleGeneratePost = (link) => {
        setModalTopic(link);
        setModalIsOpen(true);
    };

    const handleOpenPost = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalTopic('');
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-start p-4">
                <div className="w-full max-w-full">
                    <Tabs>
                        <TabList>
                            {categories.map((category) => (
                                <Tab key={category.id} onClick={() => {
                                    setSelectedCategory(category);
                                    fetchCategoryArticles(category.id);
                                }}>
                                    {category.name}
                                </Tab>
                            ))}
                        </TabList>
                        {categories.map((category) => (
                            <TabPanel key={category.id}>
                                <Tabs>
                                    <TabList>
                                        <Tab onClick={() => fetchCategoryArticles(category.id)}>Content</Tab>
                                        <Tab onClick={() => setSelectedCategory(category)}>My Topics</Tab>
                                    </TabList>
                                    <TabPanel>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <CategoryContent
                                                articles={categoryArticles}
                                                handleOpenPost={handleOpenPost}
                                                handleGeneratePost={handleGeneratePost}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="flex justify-between items-center mb-4">
                                            <div className="cursor-pointer px-4 py-2 bg-white border border-black rounded transition-colors duration-300">
                                                <input
                                                    type="text"
                                                    placeholder="Add Topic"
                                                    value={newTopicName}
                                                    onChange={(e) => setNewTopicName(e.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleCreateTopic();
                                                        }
                                                    }}
                                                    className="w-full bg-transparent outline-none"
                                                />
                                            </div>
                                            <button
                                                onClick={handleCreateTopic}
                                                className="ml-2 px-4 py-2 bg-black text-white rounded"
                                                disabled={creatingTopic}
                                            >
                                                {creatingTopic ? <Loader /> : 'Add'}
                                            </button>
                                        </div>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex">
                                                <div className="w-1/4">
                                                    <TopicTabs
                                                        topics={topics.filter((topic) => selectedCategory && topic.category === selectedCategory.id)}
                                                        selectedTopic={selectedTopic}
                                                        setSelectedTopic={setSelectedTopic}
                                                        fetchTopicArticles={fetchTopicArticles}
                                                        handleRefreshTopic={handleRefreshTopic}
                                                    />
                                                </div>
                                                <div className="w-3/4">
                                                    <CategoryContent
                                                        articles={topicArticles}
                                                        handleOpenPost={handleOpenPost}
                                                        handleGeneratePost={handleGeneratePost}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </TabPanel>
                                </Tabs>
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </div>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="w-11/12 h-4/5 overflow-auto bg-white p-4 border border-black rounded shadow-lg"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <button onClick={closeModal} className="absolute top-20 right-20 p-2">x</button>
                <MindPalaceGenerator passedTopic={modalTopic} />
            </Modal>
        </div>
    );
};

export default ContentManager;