import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import CustomDropdown from '../components/CustomDropdownWithLabel';
import { getInfluencers } from "../utils/apis/getInfluencers";
import { getTones } from "../utils/apis/getTones";
import { getLengths } from "../utils/apis/getLengths";
import { generatePost } from "../utils/apis/generatePost";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GeneratedPost from "../components/GeneratedPost";
import PostForm from "../components/PostForm";
import * as amplitude from "@amplitude/analytics-browser";

const HomePage = () => {
    const [influencers, setInfluencers] = useState([]);
    const [tones, setTones] = useState([]);
    const [lengths, setLengths] = useState([]);
    const [selectedInfluencer, setSelectedInfluencer] = useState('');
    const [selectedTone, setSelectedTone] = useState('');
    const [selectedLength, setSelectedLength] = useState('');
    const [topic, setTopic] = useState('');
    const [generatedPost, setGeneratedPost] = useState('');
    const [loading, setLoading] = useState(false);
    const [modifyTone, setModifyTone] = useState(false);
    const [modifyLength, setModifyLength] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const influencersResult = await getInfluencers();
            if (influencersResult.success){
                setInfluencers(influencersResult.data);
            }

            const tonesResult = await getTones();
            if (tonesResult.success) {
                setTones(tonesResult.data);
            }

            const lengthsResult = await getLengths();
            if (lengthsResult.success) {
                setLengths(lengthsResult.data);
            }
        };
        fetchData();

        const queryParams = new URLSearchParams(location.search);
        const urlTopic = queryParams.get('topic');
        if (urlTopic) {
            setTopic(urlTopic);
        }
    }, [location]);

    const handleGeneratePost = async (finalTopic) => {
        if (!selectedInfluencer || (!selectedTone && modifyTone) || (!selectedLength && modifyLength) || !finalTopic) {
            toast.error('please select all fields (creator, tone, length & topic)');
            amplitude.track('Post Generation Failure',{
                reason:"invalid selection"
            });
            return;
        }

        const tone = modifyTone ? selectedTone : 'Influencer Style';
        const length = modifyLength ? selectedLength : 'Influencer Style';

        setLoading(true);
        const result = await generatePost(tone, length, selectedInfluencer, finalTopic);
        if (result.success) {
            setGeneratedPost(result.data.post);
            amplitude.track('Post Generation Successful',{
                selectedTone:tone,
                selectedInfluencer:selectedInfluencer,
                selectedLength:length,
                topic:finalTopic,
            });
        } else {
            toast.error('failed to generate post');
            amplitude.track('Post Generation Failure',{
                selectedTone:tone,
                selectedInfluencer:selectedInfluencer,
                selectedLength:length,
                topic:finalTopic,
                reason:"API"
            });
        }
        setLoading(false);
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(generatedPost);
        toast.success('post copied to clipboard');
        amplitude.track('Post Copied',{
            post:generatedPost
        });
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                <div className="flex flex-col lg:flex-row w-full max-w-10xl justify-center">
                    <PostForm
                        influencers={influencers}
                        tones={tones}
                        lengths={lengths}
                        selectedInfluencer={selectedInfluencer}
                        setSelectedInfluencer={setSelectedInfluencer}
                        selectedTone={selectedTone}
                        setSelectedTone={setSelectedTone}
                        selectedLength={selectedLength}
                        setSelectedLength={setSelectedLength}
                        topic={topic}
                        setTopic={setTopic}
                        handleGeneratePost={handleGeneratePost}
                        loading={loading}
                        modifyTone={modifyTone}
                        setModifyTone={setModifyTone}
                        modifyLength={modifyLength}
                        setModifyLength={setModifyLength}
                        hideTopicInput={!!location.search}
                        title={"generate post"}
                    />
                    {generatedPost && (
                        <GeneratedPost
                            generatedPost={generatedPost}
                            handleCopyToClipboard={handleCopyToClipboard}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;