import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCategories } from "../../utils/apis/mindpalace/getCategories";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import {
    CircleMenu,
    CircleMenuItem,
    TooltipPlacement,
} from "react-circular-menu";
import BusinessIcon from '@mui/icons-material/Business';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MemoryIcon from '@mui/icons-material/Memory';
import ComputerIcon from '@mui/icons-material/Computer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SchoolIcon from '@mui/icons-material/School';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import StyleIcon from '@mui/icons-material/Style';
import SpaIcon from '@mui/icons-material/Spa';
import EcoIcon from '@mui/icons-material/Spa';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FlightIcon from '@mui/icons-material/Flight';
import MovieIcon from '@mui/icons-material/Movie';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StarIcon from '@mui/icons-material/Star';

const categoryIcons = {
    "Sales & Marketing": <TrendingUpIcon style={{ fontSize: '2rem' }} />,
    "Operations": <BuildIcon style={{ fontSize: '2rem' }} />,
    "Finance": <AccountBalanceIcon style={{ fontSize: '2rem' }} />,
    "Web3 & Crypto": <CurrencyBitcoinIcon style={{ fontSize: '2rem' }} />,
    "Entrepreneurship & Leadership": <LeaderboardIcon style={{ fontSize: '2rem' }} />,
    "AI & ML": <MemoryIcon style={{ fontSize: '2rem' }} />,
    "IT & Technology": <ComputerIcon style={{ fontSize: '2rem' }} />,
    "Health & Fitness": <FitnessCenterIcon style={{ fontSize: '2rem' }} />,
    "Personal Growth": <SelfImprovementIcon style={{ fontSize: '2rem' }} />,
    "Education": <SchoolIcon style={{ fontSize: '2rem' }} />,
    "Gaming": <SportsEsportsIcon style={{ fontSize: '2rem' }} />,
    "Fashion & Beauty": <StyleIcon style={{ fontSize: '2rem' }} />,
    "Religions & Spirituality": <SpaIcon style={{ fontSize: '2rem' }} />,
    "Environment & Energy": <EcoIcon style={{ fontSize: '2rem' }} />,
    "Ecommerce & Retail": <ShoppingCartIcon style={{ fontSize: '2rem' }} />,
    "Travel & Leisure": <FlightIcon style={{ fontSize: '2rem' }} />,
    "Entertainment": <MovieIcon style={{ fontSize: '2rem' }} />,
    "Startups & Business": <StorefrontIcon style={{ fontSize: '2rem' }} />,
    "Tech": <ImportantDevicesIcon style={{ fontSize: '2rem' }} />,
    "Personal Development": <SettingsAccessibilityIcon style={{ fontSize: '2rem' }} />,
    "Lifestyle & Leisure": <BrunchDiningIcon style={{ fontSize: '2rem' }} />,
    "Your Favourites": <StarIcon style={{ fontSize: '2rem' }} />,
};

const categoryData = {
    "Startups & Business": ["Startups & Business", "Sales & Marketing", "Operations", "Finance", "Entrepreneurship & Leadership", "Ecommerce & Retail"],
    "Tech": ["Web3 & Crypto", "AI & ML", "IT & Technology", "Gaming", "Environment & Energy"],
    "Personal Development": ["Health & Fitness", "Personal Growth", "Education", "Religions & Spirituality"],
    "Lifestyle & Leisure": ["Fashion & Beauty", "Travel & Leisure", "Entertainment"],
    "Your Favourites": ["Favourite"],
};

const CategoryNavigation = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const result = await getCategories();
            if (result) {
                setCategories(result);
            } else {
                console.error('Failed to load categories');
            }
        } catch (error) {
            console.error('An error occurred while fetching categories', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryClick = (category) => {
        if (category === "Your Favourites") {
            navigate(`/mind-palace/category/0000000/favourites`);
        } else {
            setSelectedCategory(category);
        }
    };

    const handleSubCategoryClick = (subCategory) => {
        const selectedSubCategory = categories.find(cat => cat.name === subCategory);
        if (selectedSubCategory) {
            navigate(`/mind-palace/category/${selectedSubCategory.id}/${selectedSubCategory.name}`);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="relative">
                        {!selectedCategory ? (
                            <CircleMenu
                                startAngle={-90}
                                rotationAngle={360}
                                itemSize={5}
                                radius={10}
                                rotationAngleInclusive={false}
                            >
                                {Object.keys(categoryData).map(parentCategory => (
                                    <CircleMenuItem
                                        key={parentCategory}
                                        tooltip={parentCategory}
                                        tooltipPlacement={TooltipPlacement.Right}
                                        onClick={() => handleCategoryClick(parentCategory)}
                                    >
                                        {categoryIcons[parentCategory]}
                                    </CircleMenuItem>
                                ))}
                            </CircleMenu>
                        ) : (
                            <CircleMenu
                                startAngle={-90}
                                rotationAngle={360}
                                itemSize={5}
                                radius={10}
                                rotationAngleInclusive={false}
                            >
                                <CircleMenuItem
                                    tooltip="Back"
                                    tooltipPlacement={TooltipPlacement.Right}
                                    onClick={() => setSelectedCategory(null)}
                                >
                                    <span style={{ fontSize: '2rem' }}>🔙</span>
                                </CircleMenuItem>
                                {categoryData[selectedCategory].map(subCategory => (
                                    <CircleMenuItem
                                        key={subCategory}
                                        tooltip={subCategory}
                                        tooltipPlacement={TooltipPlacement.Right}
                                        onClick={() => handleSubCategoryClick(subCategory)}
                                    >
                                        {categoryIcons[subCategory] || <BusinessIcon />}
                                    </CircleMenuItem>
                                ))}
                            </CircleMenu>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default CategoryNavigation;