import axiosOpenInstance from "../axiosOpenInstance";

export const verifyOtpRequest = async (otp, user_id, order_id) => {
    try {
        const response = await axiosOpenInstance.post('/user/verify/', {
            otp,
            user_id,
            order_id,
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};