import {toast} from 'react-toastify';
import axios from "axios";


const axiosOpenInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a response interceptor
axiosOpenInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors

        return Promise.reject(error);
    }
);

export default axiosOpenInstance;