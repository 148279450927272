import React, { useState } from 'react';
import CustomDropdown from './CustomDropdownWithLabel';
import CustomTextArea from './CustomTextArea';
import CustomToggle from './CustomToggle';

const PostForm = ({
                      influencers,
                      tones,
                      lengths,
                      selectedInfluencer,
                      setSelectedInfluencer,
                      selectedTone,
                      setSelectedTone,
                      selectedLength,
                      setSelectedLength,
                      topic,
                      setTopic,
                      handleGeneratePost,
                      loading,
                      modifyTone,
                      setModifyTone,
                      modifyLength,
                      setModifyLength,
                      hideTopicInput,
                      title,
                  }) => {
    const [context, setContext] = useState('');
    const [addContext, setAddContext] = useState(false);

    const handleGeneratePostWithContext = () => {
        console.log(hideTopicInput, topic)
        let finalTopic = '';
        if(hideTopicInput===true){
            finalTopic = topic;
        }
        else{
            if(addContext && context){
                finalTopic = `Context For Post Generation: ${context}`
            }
            else{
                finalTopic = topic;
            }
        }
        handleGeneratePost(finalTopic);
    };

    return (
        <div className="w-full max-w-md card mb-2 lg:mr-10">
            <h2 className="text-3xl text-black mb-10">{title}</h2>
            <div className="mb-8 influencer">
                <CustomDropdown
                    name="Influencer"
                    placeholder={"content creator"}
                    value={selectedInfluencer}
                    onChange={(e) => setSelectedInfluencer(e.target.value)}
                    options={influencers.map((influencer) => ({
                        value: influencer.name,
                        label: influencer.name,
                    }))}
                />
            </div>
            <div className="mb-8 context">
                {!hideTopicInput && <CustomToggle
                    checked={addContext}
                    onChange={() => setAddContext(!addContext)}
                    label="add context"
                />
                }
                {addContext && (
                    <div className="mb-8 context">
                        <CustomTextArea
                            type="text"
                            name="context"
                            placeholder={"content summary"}
                            value={context}
                            onChange={(e) => setContext(e.target.value)}
                            className="input bg-gray-100"
                            rows={3}
                        />
                    </div>
                )}
            </div>
            {!addContext && !hideTopicInput && (
                <div className="mb-8 topic">
                    <CustomTextArea
                        type="text"
                        name="topic"
                        placeholder={"content topic"}
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        className="input bg-gray-100"
                        rows={3}
                    />
                </div>
            )}
            <div className="mb-8 tone">
                <CustomToggle
                    checked={modifyTone}
                    onChange={() => setModifyTone(!modifyTone)}
                    label="modify tone"
                />
                {modifyTone && (
                    <CustomDropdown
                        name="Tone"
                        placeholder={"tone"}
                        value={selectedTone}
                        onChange={(e) => setSelectedTone(e.target.value)}
                        options={tones.map((tone) => ({
                            value: tone.display_text,
                            label: tone.display_text,
                        }))}
                    />
                )}
            </div>
            <div className="mb-8 length">
                <CustomToggle
                    checked={modifyLength}
                    onChange={() => setModifyLength(!modifyLength)}
                    label="modify length"
                />
                {modifyLength && (
                    <CustomDropdown
                        name="Length"
                        placeholder={"length"}
                        value={selectedLength}
                        onChange={(e) => setSelectedLength(e.target.value)}
                        options={lengths.map((length) => ({
                            value: length.display_text,
                            label: length.display_text,
                        }))}
                    />
                )}
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    className="btn flex items-center mt-4 font-bold"
                    onClick={handleGeneratePostWithContext}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <svg
                                className="animate-spin h-5 w-5 mr-3"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.966 7.966 0 014 12H2c0 2.21.896 4.21 2.343 5.657l1.414-1.414z"
                                ></path>
                            </svg>
                            generating
                        </>
                    ) : (
                        'generate post'
                    )}
                </button>
            </div>
        </div>
    );
};

export default PostForm;