import authAxiosInstance from "../authAxiosInstance";


export const getTones = async () => {
    try {
        const response = await authAxiosInstance.get('/content/tones');
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};