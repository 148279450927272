import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {clarity} from "react-microsoft-clarity";
import { init } from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));

clarity.init(process.env.REACT_APP_CLARITY_ID);
clarity.consent();

Sentry.init({
    dsn: "https://0088b7c90d41d8fc975df0c9caf97c84@o4508019312427008.ingest.us.sentry.io/4508019369312256",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    maxBreadcrumbs: 100,
    attachStacktrace: true,

});

init(process.env.REACT_APP_AMPLITUDE_KEY, {
    defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
    },
});

root.render(
    <App />
);
