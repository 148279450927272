import React from 'react';
import { FiEdit, FiEye } from 'react-icons/fi';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const Article = ({ article, openPostModal, generatePostModal, markPostAsFavourite }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short' };
        const day = date.getDate();
        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        return `${day}${suffix(day)} ${date.toLocaleDateString('en-US', options)}`;
    };
    console.log(article)

    return (
        <div key={article.id} className="bg-white border-2 border-black p-4 rounded shadow flex flex-col justify-between">
            <div>
                <div className="relative group">
                    <h4 className="text-lg font-bold mb-2 line-clamp-3">{article.title}</h4>
                    <span
                        className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-xs rounded p-1 z-10">
                        {article.title}
                    </span>
                </div>
                {/*<p className="text-sm mb-0 line-clamp-5">{article.description}</p>*/}
                <p className="text-sm mb-0">{article.source.name}</p>
                <p className="text-sm mb-2">{formatDate(article.publish_date)}</p>
            </div>
            <div className="flex justify-end mt-auto">
                <div className="flex space-x-1">
                    <button
                        className="relative btn-icon-small group"
                        onClick={() => openPostModal(article)}
                    >
                        <FiEye className="text-xs" />
                        <span className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-xs rounded p-1">
                            read full article
                        </span>
                    </button>
                    <button
                        className="relative btn-icon-small group"
                        onClick={() => generatePostModal(article)}
                    >
                        <FiEdit className="text-xs" />
                        <span className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-xs rounded p-1">
                            generate post on this
                        </span>
                    </button>
                    <button
                        className="relative btn-icon-small group"
                        onClick={() => markPostAsFavourite(article)}
                    >
                        {article.favourite ? (
                            <AiFillStar className="text-xs" />
                        ) : (
                            <AiOutlineStar className="text-xs" />
                        )}
                        <span className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-xs rounded p-1">
                            {article.favourite ? 'unmark as favourite' : 'mark as favourite'}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Article;