import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import { createPost, deletePost, getInfluencerPosts, updatePost } from '../utils/apis/InfluencerPosts';
import { FiEdit, FiTrash, FiEye } from 'react-icons/fi';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import PostModal from "../components/PostsModal";
import CustomTextArea from "../components/CustomTextArea";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocation } from 'react-router-dom';
const InfluencerPosts = () => {
    const { influencerId } = useParams();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({ content: '' });
    const [editing, setEditing] = useState(false);
    const [currentPostId, setCurrentPostId] = useState(null);
    const [isPostModalOpen, setIsPostModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');

    useEffect(() => {
        const fetchPosts = async () => {
            const result = await getInfluencerPosts(influencerId);
            if (result.success) {
                setPosts(result.data);
            } else {
                toast.error('failed to load posts');
                amplitude.track('Influencer Post Page Failure');
            }
            setLoading(false);
        };
        fetchPosts();
    }, [influencerId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSave = async () => {
        setLoading(true);
        if (editing) {
            const result = await updatePost(currentPostId, form);
            if (result.success) {
                setPosts(posts.map((post) => (post.id === currentPostId ? result.data : post)));
                toast.success('post created successfully!');
                setEditing(false);
                setCurrentPostId(null);
                amplitude.track('Influencer Post Update Successful',{
                    influencer:influencerId,
                    post:currentPostId
                });
            } else {
                toast.error(`failed to update post: ${result.error}`);
                amplitude.track('Influencer Post Update Failure',{
                    influencer:influencerId,
                    post:currentPostId
                });
            }
        } else {
            const result = await createPost(influencerId, form);
            if (result.success) {
                setPosts([...posts, result.data]);
                toast.success('post added successfully!');
                amplitude.track('Influencer Post Add Successful',{
                    influencer:influencerId
                });
            } else {
                toast.error(`failed to add post: ${result.error}`);
                amplitude.track('Influencer Post Add Failure',{
                    influencer:influencerId
                });
            }
        }
        setForm({ content: '' });
        setLoading(false);
    };

    const handleEdit = (post) => {
        setForm({ content: post.content });
        setEditing(true);
        setCurrentPostId(post.id);
    };

    const handleDelete = async (postId) => {
        setLoading(true);
        const result = await deletePost(postId);
        if (result.success) {
            setPosts(posts.filter((post) => post.id !== postId));
            toast.success('post deleted successfully!');
            amplitude.track('Influencer Post Delete Successful',{
                influencer:influencerId,
                post:postId
            });
        } else {
            toast.error(`failed to delete post: ${result.error}`);
            amplitude.track('Influencer Post Delete Failure',{
                influencer:influencerId,
                post:postId
            });
        }
        setLoading(false);
    };

    const openPostModal = (post) => {
        setSelectedPost(post);
        setIsPostModalOpen(true);
    };

    const closePostModal = () => {
        setIsPostModalOpen(false);
        setSelectedPost(null);
    };

    const openDeleteModal = (post) => {
        setSelectedPost(post);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedPost(null);
    };

    const confirmDelete = () => {
        if (selectedPost) {
            handleDelete(selectedPost.id);
            closeDeleteModal();
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                <div className="flex flex-col md:flex-row w-full max-w-6xl justify-between mb-6">
                    <div className="w-full md:w-2/4 card spacious mb-4 md:mb-0 h-full">
                        <h3 className="text-3xl mb-4 text-black ">{editing ? 'edit post' : 'add new post for '+name}</h3>
                        <div className="mb-4 flex-grow">
                            <CustomTextArea
                                name="content"
                                placeholder="content"
                                value={form.content}
                                onChange={handleChange}
                                className="input bg-gray-100 h-full"
                                rows="20"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="btn flex items-center font-bold"
                                onClick={handleSave}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.966 7.966 0 014 12H2c0 2.21.896 4.21 2.343 5.657l1.414-1.414z"
                                            ></path>
                                        </svg>
                                        please wait
                                    </>
                                ) : (
                                    'save'
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-2/4 card h-full overflow-y-auto md:mt-8">
                        <h3 className="text-3xl mb-4 text-black ">existing posts for {name}</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                            {posts.map((post) => (
                                <div key={post.id} className="card-influencer relative h-40 p-2 flex flex-col justify-between">
                                    <p className="text-sm text-gray-700">{post.content.substring(0, 50)}...</p>
                                    <div className="flex justify-end space-x-1 mt-2">
                                        <button className="btn-icon-small" onClick={() => openPostModal(post)}>
                                            <FiEye className="text-xs" />
                                        </button>
                                        <button className="btn-icon-small" onClick={() => handleEdit(post)}>
                                            <FiEdit className="text-xs" />
                                        </button>
                                        <button className="btn-icon-small" onClick={() => openDeleteModal(post)}>
                                            <FiTrash className="text-xs" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {isPostModalOpen && (
                <PostModal
                    isOpen={isPostModalOpen}
                    onClose={closePostModal}
                    postContent={selectedPost?.content}
                    influencerName="Influencer Name"
                    influencerTitle="Influencer Title"
                    profileImage="path/to/image"
                />
            )}
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    onConfirm={confirmDelete}
                    content={"post"}
                />
            )}
        </div>
    );
};

export default InfluencerPosts;