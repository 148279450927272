import React from 'react';
import {FiEdit, FiEye, FiLinkedin, FiTrash} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const InfluencerCard = ({ influencer, handleEdit, handleDelete }) => {
    const navigate = useNavigate();

    return (
        <div className="card-influencer relative h-40 p-2 flex flex-col justify-between">
            <div>
                <a
                    href={influencer.linkedin_profile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-l mb-1 text-black  hover:underline font-bold"
                >
                    {influencer.name}
                </a>
                <p className="mb-1 text-sm text-gray-700">
                    {influencer.linkedin_bio.length > 30 ? `${influencer.linkedin_bio.substring(0, 30)}...` : influencer.linkedin_bio}
                </p>
            </div>
            <div className="flex justify-end space-x-1 mt-2">
                <button className="btn-icon-small">
                    <a
                        href={influencer.linkedin_profile}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-icon-href"
                    >
                        <FiLinkedin className="text-xs"/>
                    </a>
                </button>
                <button className="btn-icon-small" onClick={() => handleEdit(influencer)}>
                    <FiEdit className="text-xs"/>
                </button>
                <button className="btn-icon-small"
                        onClick={() => navigate(`/creator-posts/${influencer.id}?name=${influencer.name}`)}>
                    <FiEye className="text-xs"/>
                </button>
                <button className="btn-icon-small"
                        onClick={() => handleDelete(influencer)}>
                    <FiTrash className="text-xs"/>
                </button>
            </div>
        </div>
    );
};

export default InfluencerCard;