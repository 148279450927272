import authAxiosInstance from "../authAxiosInstance";


export const createUserPlan = async (plan_id) => {
    try {
        const response = await authAxiosInstance.post('/plan/subscribe/', {
            plan_id,
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};