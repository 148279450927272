import authAxiosInstance from "../../authAxiosInstance";


export const createTopic = async (name, categoryId) => {
    try {
        const response = await authAxiosInstance.post('/mindpalace/topics/', { name:name, category_id: categoryId });
        return response.data;
    } catch (error) {
        console.error('Error creating topic:', error);
        return null;
    }
};