import authAxiosInstance from "../../authAxiosInstance";


export const getCategoryArticles = async (categoryId, search) => {
    try {
        if(categoryId==='0000000'){
            //favourite/articless/
            const response = await authAxiosInstance.get(`/mindpalace/favourite/articless/?search=${search}`);
            return response.data;
        }
        else{
            const response = await authAxiosInstance.get(`/mindpalace/categories/${categoryId}/articles/?search=${search}`);
            return response.data;
        }
    } catch (error) {
        console.error('Error fetching category articles:', error);
        return [];
    }
};