import React, { useRef, useEffect } from 'react';

const OtpInput = ({ value, valueLength, onChange }) => {
    const inputs = useRef([]);

    useEffect(() => {
        inputs.current = inputs.current.slice(0, valueLength);
        // Set focus to the first input when the component mounts
        if (inputs.current[0]) {
            inputs.current[0].focus();
        }
    }, [valueLength]);

    const handleChange = (e, index) => {
        const val = e.target.value;
        if (/[^0-9]/.test(val)) {
            return;
        }
        const newOtp = value.split('');
        newOtp[index] = val;
        onChange(newOtp.join(''));

        if (val !== '' && index < valueLength - 1) {
            inputs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !value[index] && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    return (
        <div className="flex space-x-2">
            {Array(valueLength).fill('').map((_, i) => (
                <input
                    key={i}
                    type="tel"
                    maxLength="1"
                    value={value[i] || ''}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleKeyDown(e, i)}
                    ref={(el) => inputs.current[i] = el}
                    className="w-12 h-12 text-center border-2 border-black rounded input"
                />
            ))}
        </div>
    );
};

export default OtpInput;