import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirm Plan"
            className="modal"
            overlayClassName="overlay"
        >
            <h2 className="text-3xl mb-4">confirm plan</h2>
            <p>do you want to start this plan?</p>
            <div className="flex justify-end mt-4">
                <button className="btn mr-2 font-bold" onClick={onRequestClose}>Cancel</button>
                <button className="btn font-bold" onClick={onConfirm}>Confirm</button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;