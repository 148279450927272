import React from 'react';

const CustomTextInput = ({ value, onChange, placeholder, type, name }) => {
    return (
        <div className="relative w-full">
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                className="input w-full px-3 py-2 border rounded focus:outline-none focus:ring-1 focus:ring-black"
                placeholder=" "
            />
            <label className="absolute left-3 -top-2.5 bg-white px-1 text-sm text-black font-bold transition-all">
                {placeholder}
            </label>
        </div>
    );
};

export default CustomTextInput;