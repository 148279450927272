import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-black text-white text-center p-4">
            made with locho & luchi || <a href="https://www.youtube.com/watch?v=dVl8OVu-fD0y" className="underline" target="_blank">how postgen works?</a>
        </footer>
    );
};

export default Footer;
