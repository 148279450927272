import authAxiosInstance from "../authAxiosInstance";


export const deleteInfluencer = async (influencerId) => {
    try {
        console.log("--->", influencerId);
        const response = await authAxiosInstance.delete('/content/influencer/delete/'+influencerId+'/', {});
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};