import React from 'react';
import { FiHeart, FiMessageSquare, FiShare2, FiSend } from 'react-icons/fi';

const GeneratedPost = ({ generatedPost, handleCopyToClipboard }) => {
    return (
        <div className="w-full md:w-2/4 card mb-6 md:spacious">
            <div className="flex items-center mb-4">
                <img src="https://pbs.twimg.com/profile_images/1426248710170710021/n1DwuVDS_400x400.jpg" alt="Profile"
                     className="rounded-full w-10 h-10 mr-4"/>
                <div>
                    <h3 className="text-lg m-0">John Doe</h3>
                    <p className="text-sm text-gray-500 m-0 leading-none">Founder at postgen</p>
                </div>
            </div>
            <p className="mb-4 whitespace-pre-wrap">{generatedPost}</p>
            <div className="flex items-center space-x-4">
                <FiHeart className="text-gray-600"/>
                <FiMessageSquare className="text-gray-600"/>
                <FiShare2 className="text-gray-600" />
                <FiSend className="text-gray-600" />
            </div>
            <div className="flex justify-end mt-4">
                <button type="button" className="btn font-bold" onClick={handleCopyToClipboard}>
                    copy to clipboard
                </button>
            </div>
        </div>
    );
};

export default GeneratedPost;