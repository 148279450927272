import authAxiosInstance from "../authAxiosInstance";


export const generatePost = async (tone, length, influencer, topic) => {
    try {
        const response = await authAxiosInstance.post('/content/generate/', {
            tone,
            length,
            influencer,
            topic,
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};