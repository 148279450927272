import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {logEvent} from "@amplitude/analytics-browser";

const useTrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
        const pagePath = location.pathname;

        let pageName = 'Unknown Page';

        if (pagePath.includes('/verify')) {
            pageName = 'Verify OTP';
        } else if (pagePath.includes('/profile')) {
            pageName = 'Profile Page';
        } else if (pagePath.includes('/home')) {
            pageName = 'Home Page';
        } else if (pagePath.includes('/plans')) {
            pageName = 'Plans Page';
        } else if (pagePath.includes('/payment-status')) {
            pageName = 'Payment Status Page';
        } else if (pagePath.includes('/creators')) {
            pageName = 'Manage Influencers Page';
        } else if (pagePath.includes('/creator-posts')) {
            pageName = 'Influencer Posts Page';
        } else if (pagePath === '/') {
            pageName = 'Enter Phone Number';
        }

        logEvent(pageName + 'Page Viewed', { page: pageName });
    }, [location]);
};

export default useTrackPageView;