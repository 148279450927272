import React from 'react';

const CustomToggle = ({ checked, onChange, label }) => {
    let leftLabel = ""
    let rightLabel = ""
    if(label==='add context'){
        leftLabel = "topic";
        rightLabel = "context";
    }
    else{
        leftLabel = label.replace("modify", "default");
        rightLabel = label;
    }
    return (
        <label className="flex items-center cursor-pointer mb-4">
            <span className={`mr-2 w-32 mt-1.5 ${checked ? '' : 'font-bold'}`}>{leftLabel}</span> {/* Set a fixed width here */}
            <div className="relative">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    className="sr-only"
                />
                <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                <div
                    className={`dot absolute top-1 bg-white w-6 h-6 rounded-full transition ml-1 ${checked ? 'transform translate-x-6' : ''}`}></div>
            </div>
            <span className={`mr-2 w-32 ml-4 mt-1.5 ${checked ? 'font-bold' : ''}`}>{rightLabel}</span>
        </label>
    );
};

export default CustomToggle;