import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getPlans } from "../utils/apis/getPlans";
import { getUserProfile } from "../utils/apis/getUserProfile";
import { createUserPlan } from "../utils/apis/createUserPlan";
import ConfirmationModal from "../components/Modal";
import * as amplitude from "@amplitude/analytics-browser";

const PlansAndPricing = () => {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [processing, setProcessing] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const expire = queryParams.get('expire') === '1';
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            const result = await getPlans();
            if (result.success) {
                setPlans(result.data);
            } else {
                toast.error('failed to load plans');
            }
            setLoading(false);
        };
        fetchPlans();
    }, []);

    const handleStartPlan = (plan_id) => {
        amplitude.track('Plans Selected',{
            plan_id:plan_id
        });
        setSelectedPlan(plan_id);
        setModalIsOpen(true);
    };

    const handleConfirmPlan = async () => {
        setModalIsOpen(false);
        if (!selectedPlan) return;

        setProcessing(true);
        amplitude.track('Plans Confirmed');
        const storedProfile = localStorage.getItem('user_profile');
        let userProfile = {};

        if (storedProfile) {
            userProfile = JSON.parse(storedProfile);
        } else {
            toast.error('user profile not found in local storage');
            setProcessing(false);
            return;
        }

        const result = await createUserPlan(selectedPlan);
        if (result.success) {

            const { id, razorpay_subscription_id } = result.data;
            if (razorpay_subscription_id)
            {
                amplitude.track('Payment Attempted',{
                    razorpaySubscriptionId:razorpay_subscription_id,
                    userPlanId:id,
                });
                // Open Razorpay widget
                const options =
                {
                    key: process.env.REACT_APP_RAZORPAY_KEY, // Use the environment variable
                    subscription_id: razorpay_subscription_id,
                    prefill: {
                        name: `${userProfile.first_name} ${userProfile.last_name}`,
                        email: userProfile.email,
                        contact: userProfile.phone_number.slice(-10),
                    },
                    handler: function (response) {
                        navigate('/payment-status/'+id);
                        setProcessing(false);
                    }
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
            }
            else
            {
                amplitude.track('Free Plan Started',{
                    userPlanId:id
                });
                toast.success('plan started successfully');
                setProcessing(false);
                navigate('/home/');
            }
        }
        else
        {
            amplitude.track('Plan Start Failure',{
                planId:selectedPlan
            });
            toast.error('failed to start plan');
            setProcessing(false);
        }
        setSelectedPlan(null);
        setProcessing(false);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                <h2 className={`text-3xl mb-6 text-black `}>
                    {expire ? 'you must subscribe to continue using postgen' : 'plans and pricing'}
                </h2>
                <div className="w-full max-w-6xl flex flex-wrap justify-between">
                    {plans.map((plan) => (
                        <div key={plan.id} className="card spacious w-full md:w-1/4 m-4">
                            <h3 className="text-3xl mb-4 text-black ">{plan.name}</h3>
                            <p className="mb-4">{plan.description || 'No description available'}</p>
                            <p className="mb-4 text-lg font-bold">
                                {plan.currency} {plan.price} / month
                            </p>
                            <p className="mb-2">daily posts: {plan.daily_posts}</p>
                            <p className="mb-2">total posts: {plan.total_posts}</p>
                            <p className="mb-4">custom creators: {plan.custom_influencers}</p>
                            <button
                                type="button"
                                className="btn flex items-center justify-center font-bold"
                                onClick={() => handleStartPlan(plan.id)}
                                disabled={processing}
                            >
                                {processing && selectedPlan === plan.id ? (
                                    <>
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.966 7.966 0 014 12H2c0 2.21.896 4.21 2.343 5.657l1.414-1.414z"
                                            ></path>
                                        </svg>
                                        please wait
                                    </>
                                ) : (
                                    'get started'
                                )}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <ConfirmationModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={handleConfirmPlan}
            />
        </div>
    );
};

export default PlansAndPricing;