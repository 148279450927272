import React, { useEffect, useRef, useState } from 'react';
import svgFile from '../assets/infographic/one.svg';

const SvgEditorPage = () => {
    const canvasRef = useRef(null);
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');
    const [fontSize, setFontSize] = useState('20');
    const [fontStyle, setFontStyle] = useState('Arial');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        drawCanvas();
    }, [text1, text2, text3, fontSize, fontStyle, imageUrl]);

    const drawCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = svgFile; // Use the imported SVG file as the image source

        image.onload = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            ctx.font = `${fontSize}px ${fontStyle}`;
            ctx.fillText(text1, 50, 50);
            ctx.fillText(text2, 50, 100);
            ctx.fillText(text3, 50, 150);

            if (imageUrl) {
                const img = new Image();
                img.onload = () => {
                    ctx.drawImage(img, 200, 200, 100, 100);
                };
                img.src = imageUrl;
            }
        };
    };

    return (
        <div>
            <h1>SVG Editor</h1>
            <canvas ref={canvasRef} width="800" height="2000" style={{ border: '1px solid black' }}></canvas>
            <div>
                <div>
                    <label>Text 1:</label>
                    <input
                        type="text"
                        placeholder="Text 1"
                        value={text1}
                        onChange={(e) => setText1(e.target.value)}
                    />
                </div>
                <div>
                    <label>Text 2:</label>
                    <input
                        type="text"
                        placeholder="Text 2"
                        value={text2}
                        onChange={(e) => setText2(e.target.value)}
                    />
                </div>
                <div>
                    <label>Text 3:</label>
                    <input
                        type="text"
                        placeholder="Text 3"
                        value={text3}
                        onChange={(e) => setText3(e.target.value)}
                    />
                </div>
                <div>
                    <label>Image URL:</label>
                    <input
                        type="text"
                        placeholder="Image URL"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                </div>
                <div>
                    <label>Font Size:</label>
                    <select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                    </select>
                </div>
                <div>
                    <label>Font Style:</label>
                    <select value={fontStyle} onChange={(e) => setFontStyle(e.target.value)}>
                        <option value="Arial">Arial</option>
                        <option value="Courier">Courier</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Verdana">Verdana</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SvgEditorPage;