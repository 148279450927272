import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import OtpInput from '../components/OtpInput';
import { verifyOtpRequest } from "../utils/apis/verifyOTP";
import * as amplitude from "@amplitude/analytics-browser";
import {getUserProfile} from "../utils/apis/getUserProfile";
import {jwtDecode} from "jwt-decode";

const EnterOtp = () => {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleOtpChange = (newOtp) => {
        setOtp(newOtp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let user_id = localStorage.getItem('user_id');
        const order_id = localStorage.getItem('order_id');

        const result = await verifyOtpRequest(otp, user_id, order_id);

        if (result.success) {
            const { access, refresh, registered } = result.data;
            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);
            let user_id = localStorage.getItem('user_id');
            let user_profile = localStorage.getItem('user_profile');
            if(! user_id){
                const decodedToken = jwtDecode(access);
                const user_id = decodedToken.sub;
                localStorage.setItem('user_id', user_id);
            }

            if(! user_profile){
                const profileResult = await getUserProfile(user_id);
                if (profileResult.success) {
                    localStorage.setItem('user_profile', JSON.stringify(profileResult.data));
                }
                else{
                    console.log("Error In User Profile")
                }
            }


            toast.success('otp verified successfully!');
            amplitude.track('OTP Verification Successful', {
                otp: otp
            });

            if (registered) {
                navigate('/home');
                amplitude.track('Registered User');
            } else {
                navigate('/profile');  // Redirect to the profile page
                amplitude.track('New User');
            }
        } else {
            toast.error('otp verification failed. please try again');
            amplitude.track('OTP Verification Failure', {
                otp: otp
            });
        }

        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md card spacious">
                <h2 className="text-3xl mb-6 text-black ">verification</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label className="block text-black text-sm  mb-2" htmlFor="otp">
                            please enter the otp received on mobile
                        </label>
                        <OtpInput value={otp} valueLength={6} onChange={handleOtpChange} />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="btn font-bold"
                            disabled={loading}
                        >
                            {loading ? <Loader/> : 'verify otp'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EnterOtp;