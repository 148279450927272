import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiExternalLink, FiEdit } from 'react-icons/fi';
import Modal from 'react-modal';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import authAxiosInstance from '../utils/authAxiosInstance';
import MindPalaceGenerator from './MindPalaceGenerator';

Modal.setAppElement('#root'); // Set the app element for accessibility

const MindPalace = () => {
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [newTopicName, setNewTopicName] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTopic, setModalTopic] = useState('');

    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            setLoading(true);
            const result = await authAxiosInstance.get('/content/topics/');
            if (result.data) {
                setTopics(result.data);
            } else {
                toast.error('Failed to load topics');
            }
            setLoading(false);
        } catch (error) {
            toast.error('An error occurred while fetching topics');
            setLoading(false);
        }
    };

    const handleCreateTopic = async () => {
        if (!newTopicName.trim()) {
            toast.error('Please enter a topic name');
            return;
        }
        try {
            setLoading(true);
            const result = await authAxiosInstance.post('/content/topic/', {
                name: newTopicName,
            });
            if (result.data.status === 'RSS feed processed') {
                toast.success('Topic created successfully!');
                setNewTopicName('');
                fetchTopics();
            } else {
                toast.error('Failed to create topic');
            }
            setLoading(false);
        } catch (error) {
            toast.error('An error occurred while creating the topic');
            setLoading(false);
        }
    };

    const fetchTopicContent = async (topicName) => {
        try {
            setLoading(true);
            const result = await authAxiosInstance.get(`/content/topic/${topicName}`);
            if (result.data) {
                setSelectedTopic(result.data);
            } else {
                toast.error('Failed to load topic content');
            }
            setLoading(false);
        } catch (error) {
            toast.error('An error occurred while fetching topic content');
            setLoading(false);
        }
    };

    const handleGeneratePost = (link) => {
        setModalTopic('Read the article here : '+link+'. Once you have read it, create a post basis the content of the article you read.');
        setModalIsOpen(true);
    };

    const handleOpenPost = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalTopic('');
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                <div className="w-full max-w-6xl">
                    <div className="flex overflow-x-auto pb-2">
                        {topics.map((topic) => (
                            <div
                                key={topic.id}
                                onClick={() => fetchTopicContent(topic.name)}
                                className={`cursor-pointer px-4 py-2 mr-2 bg-white border border-black rounded ${
                                    selectedTopic && selectedTopic.name === topic.name ? 'bg-black text-white' : 'bg-white text-black'
                                } transition-colors duration-300`}
                            >
                                {topic.name}
                            </div>
                        ))}
                        <div
                            className="cursor-pointer px-4 py-2 bg-white border border-black rounded transition-colors duration-300"
                        >
                            <input
                                type="text"
                                placeholder="Add Topic"
                                value={newTopicName}
                                onChange={(e) => setNewTopicName(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleCreateTopic();
                                    }
                                }}
                                className="w-full bg-transparent outline-none"
                            />
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        selectedTopic && (
                            <div className="mt-4">
                                <h3 className="text-2xl mb-4 text-black">{selectedTopic.name}</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {selectedTopic.posts.map((post) => (
                                        <div key={post.id} className="bg-white border border-black p-4 rounded shadow">
                                            <h4 className="text-lg font-bold mb-2">{post.name}</h4>
                                            <p className="text-sm mb-2">{post.description}</p>
                                            <div className="flex space-x-2">
                                                <button
                                                    onClick={() => handleOpenPost(post.link)}
                                                    className="text-black"
                                                >
                                                    <FiExternalLink />
                                                </button>
                                                <button
                                                    onClick={() => handleGeneratePost(post.link)}
                                                    className="text-black"
                                                >
                                                    <FiEdit />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="w-11/12 h-4/5 overflow-auto bg-white p-4 border border-black rounded shadow-lg"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <button onClick={closeModal} className="absolute top-20 right-20 padding-0">x</button>
                <MindPalaceGenerator passedTopic={modalTopic} />
            </Modal>
        </div>
    );
};

export default MindPalace;