import authAxiosInstance from "../../authAxiosInstance";


export const getTopicArticles = async (topicId) => {
    try {
        const response = await authAxiosInstance.get(`/mindpalace/topics/${topicId}/articles/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching topic articles:', error);
        return [];
    }
};