import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { getUserProfile } from "../utils/apis/getUserProfile";
import { updateUserProfile } from "../utils/apis/updateUserProfile";
import CustomTextInput from "../components/CustomTextInput";
import * as amplitude from "@amplitude/analytics-browser";

const ProfilePage = () => {
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: ''
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchProfile = async () => {
            const user_id = localStorage.getItem('user_id');
            const result = await getUserProfile(user_id);
            if (result.success) {
                setProfile(result.data);
            } else {
                toast.error('failed to load profile data');
            }
            setLoading(false);
        };
        fetchProfile();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!profile.first_name) newErrors.first_name = 'First Name is required';
        if (!profile.last_name) newErrors.last_name = 'Last Name is required';
        if (!profile.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(profile.email)) {
            newErrors.email = 'Email address is invalid';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSave = async () => {
        if (!validate()) {
            return;
        }

        setLoading(true);
        const user_id = localStorage.getItem('user_id');
        const result = await updateUserProfile(user_id, profile);
        if (result.success) {
            /*
                {"phone_number":"918686543210","first_name":"Ankur","last_name":"Jalan","email":"hi@ankurjalan.com","active_plan":null}
             */
            localStorage.setItem('user_profile', JSON.stringify(result.data));
            amplitude.track('Profile Update Success',{
                reason:JSON.stringify(result.data)
            });
            const identifyEvent = new amplitude.Identify();
            identifyEvent.set('Phone Number', result.data.phone_number);
            identifyEvent.set('First Name', result.data.first_name);
            identifyEvent.set('Last Name', result.data.last_name);
            identifyEvent.set('EMail', result.data.email);
            amplitude.identify(identifyEvent);
            toast.success('profile created successfully!');
            if (result.data.active_plan) {
                navigate('/home');
            } else {
                navigate('/plans');
            }
        } else {
            toast.error(`failed to update profile: ${result.error}`);
            amplitude.track('Profile Update Failure',{
                reason:"API"
            });
        }
        setLoading(false);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md card spacious">
                <h2 className="text-3xl mb-6 text-black ">create your account</h2>
                <div className="mb-6 flex space-x-4">
                    <div className="flex-1">
                        <CustomTextInput
                            type="text"
                            name="first_name"
                            placeholder="first name"
                            value={profile.first_name}
                            onChange={handleChange}
                            className={`input bg-gray-100 ${errors.first_name ? 'border-red-500' : ''}`}
                        />
                        {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                    </div>
                    <div className="flex-1">
                        <CustomTextInput
                            type="text"
                            name="last_name"
                            placeholder="last name"
                            value={profile.last_name}
                            onChange={handleChange}
                            className={`input bg-gray-100 ${errors.last_name ? 'border-red-500' : ''}`}
                        />
                        {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                    </div>
                </div>
                <div className="mb-6">
                    <CustomTextInput
                        type="email"
                        name="email"
                        placeholder="email"
                        value={profile.email}
                        onChange={handleChange}
                        className={`input bg-gray-100 ${errors.email ? 'border-red-500' : ''}`}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="mb-6">
                    <input
                        type="text"
                        name="phone_number"
                        value={profile.phone_number}
                        className="input bg-gray-200 cursor-not-allowed"
                        disabled
                        hidden={true}
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="btn"
                        onClick={handleSave}
                    >
                        save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;