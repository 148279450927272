import authAxiosInstance from "../../authAxiosInstance";


export const getCategoryTopics = async (categoryId) => {
    try {
        const response = await authAxiosInstance.get(`/mindpalace/categories/${categoryId}/topics/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching topics:', error);
        return [];
    }
};