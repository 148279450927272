import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import EnterPhoneNumber from './pages/EnterPhoneNumber';
import EnterOtp from './pages/EnterOtp';
import ProfilePage from './pages/ProfilePage';
import HomePage from './pages/HomePage';
import SelectPlan from './pages/SelectPlan';
import PaymentStatus from './pages/PaymentStatus';
import ErrorBoundary from './components/ErrorBoundary';
import ManageInfluencers from "./pages/ManageInfluencers";
import InfluencerPosts from "./pages/InfluencerPosts";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import useTrackPageView from "./components/useTrackPageView";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import LinkedInCallback from "./pages/LinkedinCallBack";
import MindPalace from "./pages/MindPalace";
import ContentManager from "./pages/ContentManager";
import CategoryNavigation from "./pages/MindPalace/CategoryNavigation";
import MindPalaceList from "./pages/MindPalace/List";
import SvgEditorPage from "./pages/SvgEditorPage"; // Assuming your global styles are here
function App() {

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    return (
        <ErrorBoundary>
            <Router>
                <FloatingWhatsApp
                    phoneNumber="919879178881"
                    accountName="postgen by TCC"
                    avatar="https://pbs.twimg.com/profile_images/1426248710170710021/n1DwuVDS_400x400.jpg"
                    statusMessage="Typically replies within 5 minutes"
                    chatMessage="Hello! How can we help you?"
                />
                <PageTracker>
                    <div className="app">
                        <Routes>
                            <Route path="/" element={<EnterPhoneNumber />} />
                            <Route path="/verify" element={<EnterOtp />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/plans" element={<SelectPlan />} />
                            <Route path="/payment-status/:id" element={<PaymentStatus />} />
                            <Route path="/creators" element={<ManageInfluencers />} />
                            <Route path="/creator-posts/:influencerId" element={<InfluencerPosts />} />
                            <Route path="/linkedin" element={<LinkedInCallback />} />
                            <Route path="/mind-palace/category/:categoryId/:categoryName" element={<MindPalaceList />} />
                            <Route path="/mind-palace" element={<CategoryNavigation />} />
                            <Route path="/svg-editor" element={<SvgEditorPage />} />
                        </Routes>
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            theme="dark"
                        />
                    </div>
                </PageTracker>
            </Router>
        </ErrorBoundary>
    );
}

const PageTracker = ({ children }) => {
    useTrackPageView();
    return children;
};
export default App;