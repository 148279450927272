import authAxiosInstance from "../../authAxiosInstance";


export const getCategories = async () => {
    try {
        const response = await authAxiosInstance.get('/mindpalace/categories/');
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};