import React from 'react';
import { FiHeart, FiMessageSquare, FiShare2, FiSend } from 'react-icons/fi';

const PostModal = ({ isOpen, onClose, postContent, influencerName, influencerTitle, profileImage }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative">
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    onClick={onClose}
                >
                    &times;
                </button>
                <p className="mb-4 whitespace-pre-wrap">{postContent}</p>
                <div className="flex items-center space-x-4">
                    <FiHeart className="text-gray-600" />
                    <FiMessageSquare className="text-gray-600" />
                    <FiShare2 className="text-gray-600" />
                    <FiSend className="text-gray-600" />
                </div>
            </div>
        </div>
    );
};

export default PostModal;