import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Loader from '../../components/Loader';
import Articles from './Articles';
import CustomTextInput from '../../components/CustomTextInput';
import { styled } from '@mui/material/styles';
import { getCategoryTopics } from "../../utils/apis/mindpalace/getCategoryTopics";
import { getTopicArticles } from "../../utils/apis/mindpalace/getTopicArticles";
import { createTopic } from "../../utils/apis/mindpalace/createTopic";

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'black',
        height: '3px',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'lowercase',
    fontWeight: '600',
    marginRight: theme.spacing(1),
    color: 'black',
    '&.Mui-selected': {
        color: 'black',
    },
    '&:hover': {
        boxShadow: '0px -2px 0px 0px black inset',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'black',
        color: 'white',
    },
}));

const Topics = ({generatePostModal}) => {
    const { categoryId, categoryName } = useParams();
    const [topics, setTopics] = useState([]);
    const [loadingTopics, setLoadingTopics] = useState(true);
    const [selectedTopicId, setSelectedTopicId] = useState(null);
    const [articles, setArticles] = useState([]);
    const [newTopic, setNewTopic] = useState("");
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [addingTopic, setAddingTopic] = useState(false);

    useEffect(() => {
        const fetchTopics = async () => {
            const result = await getCategoryTopics(categoryId);
            if (result) {
                setTopics(result);
            } else {
                toast.error('Failed to load topics');
            }
            setLoadingTopics(false);
        };
        fetchTopics();
    }, [categoryId]);

    const handleTopicChange = async (event, newValue) => {
        setSelectedTopicId(newValue);
        setLoadingArticles(true);
        const result = await getTopicArticles(newValue);
        if (result) {
            setArticles(result);
        } else {
            toast.error('Failed to load topic articles');
        }
        setLoadingArticles(false);
    };

    const handleAddTopic = async () => {
        setAddingTopic(true);
        const result = await createTopic(newTopic, categoryId);
        if (result) {
            setTopics([...topics, result]);
            setNewTopic("");
            setSelectedTopicId(result.id);
            handleTopicChange(null, result.id);
        } else {
            toast.error('Failed to add topic');
        }
        setAddingTopic(false);
    };

    return (
        <div className="flex flex-col">
            <h3 className="text-md mb-4 text-black">
                ⚡️ can’t find what you need? try searching for topics in {categoryName.toLowerCase()} here to discover
                articles on your topic.
            </h3>
            <div className="block md:hidden mb-2">
                <h3 className="text-md font-bold">this page works best from a tab/desktop/macbook</h3>
            </div>
            <div className="flex">
                <div className="w-1/4 mr-2">
                    <div className="flex items-center justify-top">
                        <CustomTextInput
                            type="text"
                            value={newTopic}
                            onChange={(e) => setNewTopic(e.target.value)}
                            placeholder="topic"
                            className="input bg-gray-100 flex-grow"
                        />
                        <button onClick={handleAddTopic} className="btn ml-2 -mt-3.5" disabled={addingTopic}>
                            {addingTopic ? 'Fetching...' : 'Search'}
                        </button>
                    </div>
                    <StyledTabs
                        orientation="vertical"
                        value={selectedTopicId}
                        onChange={handleTopicChange}
                    >
                        {topics.map((topic) => (
                            <StyledTab label={topic.name} key={topic.id} value={topic.id}/>
                        ))}
                    </StyledTabs>
                </div>
                <div className="w-3/4">
                    {loadingArticles ? (
                        <Loader/>
                    ) : (
                        <Articles articles={articles} generatePostModal={generatePostModal}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Topics;