const countryOptions = [
    { label: '🇮🇳 +91' },
    { label: '🇺🇸 +1' },
    { label: '🇬🇧 +44' },
    { label: '🇦🇺 +61' },
    { label: '🇯🇵 +81' },
    { label: '🇩🇪 +49' },
    { label: '🇫🇷 +33' },
    { label: '🇮🇹 +39' },
    { label: '🇨🇳 +86' },
    { label: '🇷🇺 +7' },
    { label: '🇪🇸 +34' },
    { label: '🇧🇷 +55' },
    { label: '🇿🇦 +27' },
    { label: '🇰🇷 +82' },
    { label: '🇨🇦 +1' },
    { label: '🇲🇽 +52' },
    { label: '🇸🇦 +966' },
    { label: '🇹🇷 +90' },
    { label: '🇦🇪 +971' },
    { label: '🇸🇬 +65' },
    { label: '🇳🇿 +64' },
    { label: '🇳🇱 +31' },
    { label: '🇧🇪 +32' },
    { label: '🇸🇪 +46' },
    { label: '🇳🇴 +47' },
    { label: '🇫🇮 +358' },
    { label: '🇩🇰 +45' },
    { label: '🇦🇹 +43' },
    { label: '🇨🇭 +41' },
    { label: '🇵🇹 +351' },
    { label: '🇬🇷 +30' },
    { label: '🇦🇷 +54' },
    { label: '🇨🇴 +57' },
    { label: '🇵🇪 +51' },
    { label: '🇻🇪 +58' },
    { label: '🇨🇱 +56' },
    { label: '🇲🇾 +60' },
    { label: '🇹🇭 +66' },
    { label: '🇵🇭 +63' },
    { label: '🇮🇩 +62' },
    { label: '🇵🇰 +92' },
    { label: '🇧🇩 +880' },
    { label: '🇻🇳 +84' },
    { label: '🇱🇰 +94' },
    { label: '🇳🇵 +977' },
    { label: '🇿🇲 +260' },
    { label: '🇰🇪 +254' },
    { label: '🇳🇬 +234' },
    { label: '🇬🇭 +233' },
    { label: '🇪🇬 +20' },
    { label: '🇮🇶 +964' },
    { label: '🇮🇷 +98' },
    { label: '🇮🇱 +972' },
    { label: '🇯🇴 +962' },
    { label: '🇱🇧 +961' },
    { label: '🇶🇦 +974' },
    { label: '🇧🇭 +973' },
    { label: '🇰🇼 +965' },
    { label: '🇴🇲 +968' },
    { label: '🇾🇪 +967' },
    { label: '🇦🇫 +93' },
    { label: '🇲🇳 +976' },
    { label: '🇰🇭 +855' },
    { label: '🇱🇦 +856' },
    { label: '🇲🇲 +95' },
    { label: '🇧🇹 +975' },
    { label: '🇲🇻 +960' },
    { label: '🇳🇮 +505' },
    { label: '🇭🇳 +504' },
    { label: '🇵🇦 +507' },
    { label: '🇬🇹 +502' },
    { label: '🇸🇻 +503' },
    { label: '🇨🇷 +506' },
    { label: '🇨🇺 +53' },
    { label: '🇧🇴 +591' },
    { label: '🇪🇨 +593' },
    { label: '🇵🇾 +595' },
    { label: '🇺🇾 +598' },
    { label: '🇸🇷 +597' },
    { label: '🇬🇾 +592' },
    { label: '🇯🇲 +1-876' },
    { label: '🇹🇹 +1-868' },
    { label: '🇧🇧 +1-246' },
    { label: '🇧🇸 +1-242' },
    { label: '🇬🇩 +1-473' },
    { label: '🇰🇳 +1-869' },
    { label: '🇱🇨 +1-758' },
    { label: '🇻🇨 +1-784' },
    { label: '🇩🇲 +1-767' },
    { label: '🇦🇬 +1-268' },
    { label: '🇧🇿 +501' },
    { label: '🇲🇦 +212' },
];
export default countryOptions;