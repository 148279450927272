import authAxiosInstance from '../authAxiosInstance'; // Import your authenticated axios instance

export const updateInfluencer = async (influencerId, influencerData) => {
    try {
        const response = await authAxiosInstance.put(`/content/edit-influencer/${influencerId}/`, influencerData);
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        return {
            success: false,
            error: error.response ? error.response.data : error.message,
        };
    }
};