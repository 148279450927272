import axiosOpenInstance from "../axiosOpenInstance";
import { toast } from "react-toastify";
import * as amplitude from "@amplitude/analytics-browser";

export const sendLoginRequest = async (code, state) => {
    try {
        const response = await axiosOpenInstance.post('/user/linkedinauth/', {
            code: code,
            state: state,
        });
        if (response.data.success) {
            const { access_token, refresh_token, user_id, profile,created } = response.data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('user_profile', JSON.stringify(profile));
            amplitude.setUserId(user_id);
            const identifyEvent = new amplitude.Identify();
            identifyEvent.set('Phone Number', response.data.profile.phone_number);
            identifyEvent.set('First Name', response.data.profile.first_name);
            identifyEvent.set('Last Name', response.data.profile.last_name);
            identifyEvent.set('EMail', response.data.profile.email);
            amplitude.identify(identifyEvent);

            if(created===true){
                amplitude.track('New User From Linkedin');
            }
            else{
                amplitude.track('Returning User From Linkedin');
            }
            return response.data;
        } else {
            toast.error('LinkedIn authentication failed.');
            return null;
        }
    } catch (error) {
        toast.error('An error occurred while processing LinkedIn authentication. Please try again.');
        return null;
    }
};