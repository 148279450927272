import React, { useState } from 'react';
import Article from './Article';
import { articleFavouriteToggle } from '../../utils/apis/mindpalace/articleFavouriteToggle';
import {toast} from "react-toastify";
import SlidingPanel from "react-sliding-side-panel";

const Articles = ({ articles, generatePostModal }) => {
    const [localArticles, setLocalArticles] = useState(articles);

    const openPostModal = (article) => {
        window.open(article.link, '_blank');
    };

    const markPostAsFavourite = async (article) => {
        try {
            const result = await articleFavouriteToggle(article.id);
            if (result) {
                if(!article.favourite){
                    toast.success('article added to favourites');
                }
                else{
                    toast.warn('article removed from favourites');
                }
                setLocalArticles(prevArticles =>
                    prevArticles.map(a =>
                        a.id === article.id ? { ...a, favourite: !a.favourite } : a
                    )
                );
                console.log('Article favourite status toggled successfully');
            } else {
                console.error('Failed to toggle favourite status');
            }
        } catch (error) {
            console.error('An error occurred while toggling favourite status', error);
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {localArticles.map(article => (
                <Article
                    key={article.id}
                    article={article}
                    openPostModal={openPostModal}
                    generatePostModal={generatePostModal}
                    markPostAsFavourite={markPostAsFavourite}
                />
            ))}
        </div>
    );
};

export default Articles;