import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import InfluencerCard from '../components/InfluencerCard';
import { getMyInfluencers } from '../utils/apis/getMyInfluencers';
import { createInfluencer } from '../utils/apis/createInfluencer';
import { updateInfluencer } from '../utils/apis/updateInfluencer';
import CustomTextInput from "../components/CustomTextInput";
import CustomTextArea from "../components/CustomTextArea";
import * as amplitude from "@amplitude/analytics-browser";
import {deleteInfluencer} from "../utils/apis/deleteInfluencer";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";

const ManageInfluencers = () => {
    const [influencers, setInfluencers] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [influencerToDelete, setInfluencerToDelete] = useState('');
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        name: '',
        linkedin_profile: '',
        linkedin_bio: ''
    });
    const [editing, setEditing] = useState(false);
    const [currentInfluencerId, setCurrentInfluencerId] = useState(null);

    const fetchInfluencers = async () => {
        //setLoading(true);
        const result = await getMyInfluencers();
        if (result.success) {
            setInfluencers(result.data);
        } else {
            toast.error('failed to load content creators');
        }
        //setLoading(false);
    };

    useEffect(() => {
        fetchInfluencers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSave = async () => {
        //setLoading(true);
        if (editing) {
            // Invoke the updateInfluencer API
            const result = await updateInfluencer(currentInfluencerId, form);
            if (result.success) {
                fetchInfluencers();
                toast.success('creator updated successfully!');
                amplitude.track('Influencer Update Successful', {
                    influencer: currentInfluencerId
                });
                setEditing(false);
                setCurrentInfluencerId(null);
            } else {
                toast.error(`failed to update creator: ${result.error}`);
                amplitude.track('Influencer Update Failure', {
                    influencer: currentInfluencerId
                });
            }
        } else {
            const result = await createInfluencer(form);
            if (result.success) {
                fetchInfluencers();
                toast.success('content creator added successfully!');
                amplitude.track('Influencer Addition Successful', {
                    form: form
                });
            } else {
                toast.error(`failed to add content creator: ${result.error}`);
                amplitude.track('Influencer Addition Failure', {
                    form: form
                });
            }
        }
        setForm({
            name: '',
            linkedin_profile: '',
            linkedin_bio: ''
        });
        //setLoading(false);
    };

    const handleEdit = (influencer) => {
        setForm({
            name: influencer.name,
            linkedin_profile: influencer.linkedin_profile,
            linkedin_bio: influencer.linkedin_bio
        });
        setEditing(true);
        setCurrentInfluencerId(influencer.id);
    };

    const openDeleteModal = (influencer) => {
        setInfluencerToDelete(influencer.id);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setInfluencerToDelete(null);
    };

    const confirmDelete = () => {
        if (influencerToDelete) {
            handleDelete(influencerToDelete);
            closeDeleteModal();
        }
    };

    const handleDelete = async (influencerToBeDelete) => {
        const result = await deleteInfluencer(influencerToBeDelete);
        console.log(result);
        if (result.success) {
            fetchInfluencers();
            toast.success('content creator deleted successfully!');
            amplitude.track('Influencer Deletion Successful', {
                influencer: influencerToBeDelete
            });
        } else {
            amplitude.track('Influencer Deletion Failure', {
                influencer: influencerToBeDelete
            });
        }
    }
    if (loading) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center">
                <div className="flex flex-col md:flex-row w-full max-w-6xl justify-between mb-6 h-full">
                    <div className="w-full md:w-1/3 card spacious mb-4 md:mb-0 h-full overflow-y-auto">
                        <h3 className="text-3xl mb-4 text-black ">{editing ? 'edit creator' : 'add new creator'}</h3>
                        <div className="mb-4">
                            <CustomTextInput
                                type="text"
                                name="name"
                                placeholder={"name"}
                                value={form.name}
                                onChange={handleChange}
                                className="input bg-gray-100"
                            />
                        </div>
                        <div className="mb-4">
                            <CustomTextInput
                                type="text"
                                name="linkedin_profile"
                                placeholder={"linkedIn profile url"}
                                value={form.linkedin_profile}
                                onChange={handleChange}
                                className="input bg-gray-100"
                            />
                        </div>
                        <div className="mb-4">
                            <CustomTextArea
                                name="linkedin_bio"
                                value={form.linkedin_bio}
                                placeholder={"linkedIn bio"}
                                onChange={handleChange}
                                className="input bg-gray-100"
                                rows="10"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="btn flex items-center font-bold"
                                onClick={handleSave}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.966 7.966 0 014 12H2c0 2.21.896 4.21 2.343 5.657l1.414-1.414z"
                                            ></path>
                                        </svg>
                                        Please Wait
                                    </>
                                ) : (
                                    'save'
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className="w-full md:w-2/3 card h-full overflow-y-auto md:mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                        <h3 className="text-3xl mb-4 text-black  col-span-full text-3xl mb-4 text-black ">your content creators</h3>
                        {influencers.map((influencer) => (
                            <InfluencerCard
                                key={influencer.id}
                                influencer={influencer}
                                handleEdit={handleEdit}
                                handleDelete={openDeleteModal}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    onConfirm={confirmDelete}
                    content={"content creator"}
                />
            )}
        </div>
    );
};

export default ManageInfluencers;