import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { sendLoginRequest } from '../utils/apis/linkedinAuth';
import { toast } from 'react-toastify';

const LinkedInCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLinkedInToken = async () => {
            const queryParams = new URLSearchParams(location.search);
            const code = queryParams.get('code');
            const state = queryParams.get('state');

            if (!code || !state) {
                toast.error('Invalid LinkedIn authentication.');
                navigate('/');
                return;
            }

            const result = await sendLoginRequest(code, state);

            if (result) {
                navigate('/home');
                console.log(result)
            } else {
                navigate('/');
            }
        };

        fetchLinkedInToken();
    }, [location, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center">
            <Loader />
        </div>
    );
};

export default LinkedInCallback;