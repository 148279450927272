import authAxiosInstance from "../authAxiosInstance";


export const getInfluencerPosts = async (influencerId) => {
    try {
        const response = await authAxiosInstance.get(`/content/influencers/${influencerId}/posts/`);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const createPost = async (influencerId, post) => {
    try {
        const response = await authAxiosInstance.post(`/content/influencers/${influencerId}/posts/`, post);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const updatePost = async (postId, post) => {
    try {
        const response = await authAxiosInstance.put(`/content/influencers/posts/${postId}/`, post);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const deletePost = async (postId) => {
    try {
        const response = await authAxiosInstance.delete(`/content/influencers/posts/${postId}/`);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};