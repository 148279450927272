import authAxiosInstance from "../authAxiosInstance";


export const updateUserProfile = async (user_id, profileData) => {
    try {
        const response = await authAxiosInstance.put(`/user/${user_id}/`, profileData);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error: error.message };
    }
};